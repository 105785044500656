import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import useCurrentLang from '../useCurrentLang';
import data from './PricelistViewer.yaml'
import { useLocation} from 'react-router-dom'

export default function PricelistViewer() {

  const query = useLocation().search.split('=')
  const loc = useCurrentLang()
  const pdfFileName = "KiteGlobing_Prebooking_Prices_2025.pdf"
  //const pdf = (query[1] || null) != pdfFileName ? pdfFileName : (query[1] || null) 
  const fileList = ["KiteGlobing_Boat_Excursions_2025.pdf" , "KiteGlobing_Prebooking_Prices_2025.pdf"]
  const pdf = fileList.includes(query[1]||'') ? query[1] : pdfFileName
  useEffect(()=>{
    if(pdf){
      //console.log(pdf)
      document.getElementById('my-pdf-viewer').src = `/pdf-viewer/web/viewer.html?file=/pdf/${pdf}`
      // document.getElementById('my-pdf-viewer').src = `/pdf-viewer/web/viewer.html`
    }
  })
  const yml = data[loc] || data.en
  const metaDesc = yml.metaDescription || yml.title

  return (
    <div id="my-div-pdf">
      <Helmet>
            <title>{yml.title}</title>
            <meta name="description" content={`${metaDesc}`} />
      </Helmet>
      <iframe
        id="my-pdf-viewer"
        name="my-pdf-viewer"
        title="KiteGlobing Price List"
        // src={`/pdf-viewer/web/viewer.html?file=${pdfpath}`}
        // src={`/pdf-viewer/web/viewer.html`}
        width="100%"
        height="1200px"
      ></iframe>
    </div>
  );
}
